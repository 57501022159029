.success {

    &-body {

        &__content {

            @include media-breakpoint-up(md) {
                @apply prose-p:text-1xl;
                width: 400px;
            }

            &-title {
                @apply text-grayDark prose-headings:text-6xl prose-headings:text-primary;
                
                @include media-breakpoint-up(md) {
                    @apply prose-headings:text-6xl;
                }
            }
        }
    }
}