.section {
    @apply py-10 lg:py-14;

    &-title {

        .captions {
            @apply prose-p:text-grayBold;
        }

        @include media-breakpoint-up(lg) {
            @apply prose-headings:text-5xl;

            .captions {
                @apply prose-p:text-1xl;
            }
        }

        .home-form & {

            .captions {
                @apply mx-auto;
            }
        }
    }

    &-screen {
        @apply bg-bottom;
        height: calc(100vh - (100px + 60px));
        
        @media (max-width: 340px) {
            @apply py-18 h-full #{!important};
        }

        @include media-breakpoint-up(md) {
            margin-top: var(--navbar-height);
            height: calc(100vh - (var(--navbar-height) + 65px));
        }

        @include media-breakpoint-up(lg) {
            background-position: -100%;
        }
    }
}