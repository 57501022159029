@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

    html {
        @apply text-rg;
    }

    body {
        @apply font-body leading-normal;
    }

    h1, h2, h3, h4, h5, h6 {
        @apply font-bold leading-tight;
    }

    h1 {
        @apply lg:text-6xl text-5xl;
    }

    h2 {
        @apply lg:text-5xl text-4xl;
    }

    h3 {
        @apply lg:text-4xl text-3xl;
    }

    h4 {
        @apply lg:text-3xl text-2xl;
    }

    h5 {
        @apply lg:text-2xl text-1xl;
    }

    h6 {
        @apply text-rg;
    }

    label {
        @apply inline-block mb-1;
    }

    input[type="checkbox"] {
        @apply w-5 h-5 bg-no-repeat bg-center border-2 rounded-xs appearance-none outline-none transition ease-in-out cursor-pointer;
    }

    input[type="radio"] {
        @apply w-5 h-5 border-2 rounded-full appearance-none outline-none cursor-pointer;
    }

    input[type="file"] {
        @apply absolute top-0 left-0 right-0 opacity-0 cursor-pointer;
    }
    
    .collapse {
        visibility: unset !important;
    }
}