.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: #fff;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 1px;
  --bs-modal-border-radius: 0.5rem;
  --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-modal-inner-border-radius: calc(0.5rem - 1px);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 1px;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 1px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 699px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 1030px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    --bs-modal-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 698.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 699.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1029.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header,
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header,
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
}
:root {
  --base-color: #000;
  --white-color: #fff;
  --navbar-height: 64px;
  --loading-size: 1.25rem;
  --loading-sm-size: 1rem;
  --loading-lg-size: 1.5rem;
  --loading-xl-size: 2rem;
}

.btn {
  --primary-color: #EC2321;
  --primary-color-invert: white;
  --primary-color-hover: #d51412;
  --secondary-color: #F4D03F;
  --secondary-color-invert: white;
  --secondary-color-hover: #f2c71b;
  --button-padding-y: 8px;
  --button-padding-x: 12px;
  --button-font-size: 1rem;
  --button-rounded: 4px;
  --button-sm-padding-y: 4px;
  --button-sm-padding-x: 8px;
  --button-sm-font-size: 0.875rem;
  --button-lg-padding-y: 12px;
  --button-lg-padding-x: 16px;
  --button-lg-font-size: 1.25rem;
  @apply inline-flex items-center justify-center border border-transparent font-semi;
  padding: var(--button-padding-y) var(--button-padding-x);
  font-size: var(--button-font-size);
  border-radius: var(--button-rounded);
  outline: 0;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-gradient {
  border: unset;
}
.btn-sm {
  padding: var(--button-sm-padding-y) var(--button-sm-padding-x);
  font-size: var(--button-sm-font-size);
}
.btn-lg {
  padding: var(--button-lg-padding-y) var(--button-lg-padding-x);
  font-size: var(--button-lg-font-size);
}
.btn-primary {
  background-color: var(--primary-color) !important;
  color: var(--primary-color-invert);
}
.btn-primary svg {
  fill: var(--primary-color-invert);
}
.btn-primary:hover {
  background-color: var(--primary-color-hover) !important;
}
.btn-outline-primary {
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.btn-outline-primary svg {
  fill: var(--primary-color);
}
.btn-outline-primary:hover {
  color: var(--primary-color-invert);
  background-color: var(--primary-color) !important;
}
.btn-outline-primary:hover svg {
  fill: var(--primary-color-invert);
}
.btn-secondary {
  background-color: var(--secondary-color) !important;
  color: var(--secondary-color-invert);
}
.btn-secondary svg {
  fill: var(--secondary-color-invert);
}
.btn-secondary:hover {
  background-color: var(--secondary-color-hover) !important;
}
.btn-outline-secondary {
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}
.btn-outline-secondary svg {
  fill: var(--secondary-color);
}
.btn-outline-secondary:hover {
  color: var(--secondary-color-invert);
  background-color: var(--secondary-color) !important;
}
.btn-outline-secondary:hover svg {
  fill: var(--secondary-color-invert);
}

.form-input, .form-textarea, .form-select {
  @apply text-black border-[#D1DBE3] rounded-xs w-full px-5 py-3 transition ease-in-out ring-0 placeholder:text-graySemi focus:border-primary !important;
}
.form-input[disabled], .form-textarea[disabled], .form-select[disabled] {
  @apply bg-gray;
}
.form-upload {
  @apply items-center;
}
@media (min-width: 699px) {
  .form-upload {
    @apply flex;
  }
}
.form-upload__button {
  @apply cursor-pointer;
}
@media (max-width: 698.98px) {
  .form-upload__button {
    @apply ml-auto;
  }
}
.form-upload__button span {
  @apply inline-flex items-center justify-center bg-[#686868] text-white py-2 px-4 rounded-xs;
}
@media (max-width: 698.98px) {
  .form-upload__button span {
    @apply mt-2;
  }
}
@media (min-width: 699px) {
  .form-upload__button span {
    @apply ml-2;
  }
}
.form-validate .form-input, .form-validate .form-textarea, .form-validate .form-select {
  @apply border-primary !important;
}
.form-validate__feedback {
  @apply mt-1 text-primary;
}
.form-validate__feedback small {
  @apply text-sm;
}
@media (max-width: 698.98px) {
  .form-captcha {
    @apply scale-90;
  }
}

@keyframes checkmark {
  0% {
    background-position-y: 5px;
  }
  50% {
    background-position-y: -2px;
  }
  100% {
    background-position-y: 0;
  }
}
.form-check {
  @apply relative inline-flex cursor-pointer;
}
.form-check input[type=checkbox] {
  @apply mt-1;
}
.form-check input[type=checkbox]:checked {
  @apply bg-transparent border-black;
  background-size: 16px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI2LjY2NjcgOS4zMzMzMUwxMy4zMzM0IDIyLjY2NjZMNi42NjY3NSAxNiIgc3Ryb2tlPSIjMUUxRTFFIiBzdHJva2Utd2lkdGg9IjMuMzMzMzMiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
  animation: checkmark 0.2s ease-in;
}
.form-check input[type=radio] {
  transition: 0.25s;
}
.form-check input[type=radio]:checked {
  @apply bg-transparent border-black;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
  animation: checkmark 0.2s ease-in;
}
.form-check label {
  @apply inline-block flex-1 pl-4 mb-0 cursor-pointer;
}

.navbar {
  transition: ease-in-out 0.25s, height 0.25s;
}
@media (max-width: 698.98px) {
  .navbar {
    height: 100px;
  }
}
@media (min-width: 699px) {
  .navbar {
    height: var(--navbar-height);
  }
}
.navbar--fixed {
  @apply bg-primaryDark;
}
.navbar--fixed .navbar-dialog {
  @apply border-b-0;
}
.navbar--fixed .navbar-logo__first {
  @apply hidden;
}
.navbar--fixed .navbar-logo__second {
  @apply block;
}
.navbar--fixed .navbar-menu__list {
  @apply text-white;
}
.navbar-dialog {
  @apply flex-wrap items-center;
}
@media (max-width: 698.98px) {
  .navbar-dialog {
    @apply flex-col flex-nowrap justify-end;
  }
}
.navbar-logo {
  @apply mr-auto prose-img:h-10;
}
@media (max-width: 698.98px) {
  .navbar-logo {
    @apply mb-4 mx-auto;
  }
}
.navbar-logo__second {
  @apply hidden;
}
@media (max-width: 699.98px) {
  .navbar-menu__list {
    @apply justify-center;
  }
}
.navbar-menu__list .active > a {
  @apply bg-primary text-white;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
  transition: opacity 0.25s;
}

.hamburger {
  @apply relative block lg:hidden w-10 h-10;
}
.hamburger-push {
  @apply flex items-center w-full h-full transition ease-in-out;
}
.hamburger-push > span {
  @apply bg-primary absolute top-5 block w-10 h-1 transition ease-in-out;
}
.hamburger-push > span::before, .hamburger-push > span::after {
  content: "";
  @apply bg-primary absolute w-10 h-1 transition ease-in-out;
}
.hamburger-push > span::before {
  @apply -top-[10px];
}
.hamburger-push > span::after {
  @apply top-[10px];
}
.hamburger-push.show > span {
  @apply bg-transparent;
}
.hamburger-push.show > span::before {
  transform: rotateZ(45deg) scaleX(1) translate(8px, 8px);
}
.hamburger-push.show > span::after {
  transform: rotateZ(-45deg) scaleX(1) translate(7px, -7px);
}
.hamburger-menu {
  top: var(--navbar-height);
  transform: translate3d(0, -100%, 0);
}
.hamburger-menu.show {
  @apply visible opacity-100;
  transform: translate3d(0, 0, 0);
}

.post-detail {
  @apply prose-headings:mb-2 prose-p:mb-4 prose-a:text-primary prose-a:font-bold prose-ul:mb-4 prose-ol:mb-4 prose-ul:pl-4 prose-ol:pl-4 prose-ul:list-disc prose-ol:list-decimal;
}
.post-detail p:last-child {
  margin-bottom: 0 !important;
}

.section {
  @apply py-10 lg:py-14;
}
.section-title .captions {
  @apply prose-p:text-grayBold;
}
@media (min-width: 1030px) {
  .section-title {
    @apply prose-headings:text-5xl;
  }
  .section-title .captions {
    @apply prose-p:text-1xl;
  }
}
.home-form .section-title .captions {
  @apply mx-auto;
}
.section-screen {
  @apply bg-bottom;
  height: calc(100vh - 160px);
}
@media (max-width: 340px) {
  .section-screen {
    @apply py-18 h-full !important;
  }
}
@media (min-width: 700px) {
  .section-screen {
    margin-top: var(--navbar-height);
    height: calc(100vh - (var(--navbar-height) + 65px));
  }
}
@media (min-width: 1030px) {
  .section-screen {
    background-position: -100%;
  }
}

@media (max-width: 699.98px) {
  .main-content {
    margin-top: 100px;
  }
}

.footer {
  background: linear-gradient(90deg, #ED1D24 0%, #AA0000 100%);
}

.stray {
  height: calc(100vh - 80px);
}
@media (min-width: 1030px) {
  .stray {
    @apply text-left;
  }
}
@media (min-width: 1030px) {
  .stray-dialog {
    @apply grid grid-cols-2 gap-16;
  }
}
.stray-image img {
  @apply mx-auto;
  height: 250px;
}
@media (min-width: 1030px) {
  .stray-image img {
    height: 500px;
  }
}
@media (min-width: 1030px) {
  .stray-body p {
    @apply mb-16 text-10xl;
  }
}

.home-form__block .content {
  @apply p-4;
}
.home-form__block .content-form__group {
  @apply mb-6;
}
.home-form__block .image {
  @apply prose-img:mx-auto;
}
@media (max-width: 1029.98px) {
  .home-form__block .image {
    @apply p-8;
  }
}
@media (max-width: 698.98px) {
  .home-form__block .image {
    @apply p-0;
  }
}
@media (min-width: 700px) {
  .home-form__block .content {
    @apply flex-1 py-8 px-12;
  }
  .home-form__block .content-form__group {
    @apply mb-10;
  }
}
@media (min-width: 1030px) {
  .home-form__block {
    @apply flex items-center;
  }
}

@media (min-width: 700px) {
  .success-body__content {
    @apply prose-p:text-1xl;
    width: 400px;
  }
}
.success-body__content-title {
  @apply text-grayDark prose-headings:text-6xl prose-headings:text-primary;
}
@media (min-width: 700px) {
  .success-body__content-title {
    @apply prose-headings:text-6xl;
  }
}