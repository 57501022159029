.navbar {
    transition: ease-in-out .25s, height .25s;

    @include media-breakpoint-down(sm) {
        height: 100px;
    }

    @include media-breakpoint-up(sm) {
        height: var(--navbar-height);
    }

    &--fixed {
        @apply bg-primaryDark;

        .navbar {

            &-dialog {
                @apply border-b-0;
            }

            &-logo {

                &__first {
                    @apply hidden;
                }

                &__second {
                    @apply block;
                }
            }
            
            &-menu {

                &__list {
                    @apply text-white;
                }
            }
        }
    }

    &-dialog {
        @apply flex-wrap items-center;

        @include media-breakpoint-down(sm) {
            @apply flex-col flex-nowrap justify-end;
        }
    }

    &-logo {
        @apply mr-auto prose-img:h-10;
        
        @include media-breakpoint-down(sm) {
            @apply mb-4 mx-auto;
        }

        &__second {
            @apply hidden;
        }
    }

    &-menu {

        &__list {

            @include media-breakpoint-down(md) {
                @apply justify-center;
            }

            .active {

                > a {
                    @apply bg-primary text-white;
                }
            }
        }
    }
}