.form {

    &-input, &-textarea, &-select {
        @apply text-black border-[#D1DBE3] rounded-xs w-full px-5 py-3 transition ease-in-out ring-0 placeholder:text-graySemi focus:border-primary #{!important};

        &[disabled] {
            @apply bg-gray;
        }
    }

    &-upload {
        @apply items-center;

        @include media-breakpoint-up(sm) {
            @apply flex;
        }

        &__button {
            @apply cursor-pointer;
            
            @include media-breakpoint-down(sm) {
                @apply ml-auto;
            }
            
            span {
                @apply inline-flex items-center justify-center bg-[#686868] text-white py-2 px-4 rounded-xs;

                @include media-breakpoint-down(sm) {
                    @apply mt-2;
                }

                @include media-breakpoint-up(sm) {
                    @apply ml-2;
                }
            }
        }
    }

    &-validate {

        .form {

            &-input, &-textarea, &-select {
                @apply border-primary #{!important};
            }
        }

        &__feedback {
            @apply mt-1 text-primary;

            small {
                @apply text-sm;
            }
        }
    }

    &-captcha {

        @include media-breakpoint-down(sm) {
            @apply scale-90;
        }
    }
}