// @font-face {
//   font-family: 'DBHelvethaicaRegular'; /* Name your font family */
//   src: url('/assets/fonts/DBHelvethaicaXv3.2.ttf'); /* Locate the .ttf file within your directory*/
// }

// @font-face {
//   font-family: 'DBHelvethaicaRegularItalic'; /* Name your font family */
//   src: url('/assets/fonts/DBHelvethaicaXItv3.2.ttf'); /* Locate the .ttf file within your directory*/
// }

// @font-face {
//   font-family: 'DBHelvethaicaMedium'; /* Name your font family */
//   src: url('/assets/fonts/DBHelvethaicaXMedv3.2.ttf'); /* Locate the .ttf file within your directory*/
// }

// @font-face {
//   font-family: 'DBHelvethaicaMediumItalic'; /* Name your font family */
//   src: url('/assets/fonts/DBHelvethaicaXMedItv3.2.ttf'); /* Locate the .ttf file within your directory*/
// }

// @font-face {
//   font-family: 'DBHelvethaicaBold'; /* Name your font family */
//   src: url('/assets/fonts/DBHelvethaicaXBdv3.2.ttf'); /* Locate the .ttf file within your directory*/
// }

// @font-face {
//   font-family: 'DBHelvethaicaBoldItalic'; /* Name your font family */
//   src: url('/assets/fonts/DBHelvethaicaXBdItv3.2.ttf'); /* Locate the .ttf file within your directory*/
// }

// @font-face {
//   font-family: 'DBHelvethaicaBlack'; /* Name your font family */
//   src: url('/assets/fonts/DBHelvethaicaXBlkv3.2.ttf'); /* Locate the .ttf file within your directory*/
// }

// @font-face {
//   font-family: 'DBHelvethaicaBlackItalic'; /* Name your font family */
//   src: url('/assets/fonts/DBHelvethaicaXBlkItv3.2.ttf'); /* Locate the .ttf file within your directory*/
// }

$root-variables: (
    // base color
    base-color: #000,
    white-color: #fff,
    // navbar
    navbar-height: 64px,

    // loading regular
    loading-size: 1.25rem,

    // loading small
    loading-sm-size: 1rem,

    // loading large
    loading-lg-size: 1.5rem,

    // loading extra large
    loading-xl-size: 2rem,
);

:root {

    @each $key, $value in $root-variables {
      --#{$key}: #{$value};
    }
  }
  