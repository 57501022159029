.home {

    &-form {

        &__block {
            
            .content {
                @apply p-4;

                &-form {

                    &__group {
                        @apply mb-6;
                    }
                }
            }

            .image {
                @apply prose-img:mx-auto;

                @include media-breakpoint-down(lg) {
                    @apply p-8;
                }

                @include media-breakpoint-down(sm) {
                    @apply p-0;
                }
            }

            @include media-breakpoint-up(md) {

                .content {
                    @apply flex-1 py-8 px-12;

                    &-form {

                        &__group {
                            @apply mb-10;
                        }
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                @apply flex items-center;
            }
        }
    }
}