$root-variables: (
    // primary color
    primary-color: #EC2321,
    primary-color-invert: #{lighten(#EC2321, 100%)},
    primary-color-hover: #{darken(#EC2321, 7.5%)},

    // secondary color
    secondary-color: #F4D03F,
    secondary-color-invert: #{lighten(#F4D03F, 100%)},
    secondary-color-hover: #{darken(#F4D03F, 7.5%)},

    // button regular
    button-padding-y: 8px,
    button-padding-x: 12px,
    button-font-size: 1rem,
    button-rounded: 4px,

    // button small
    button-sm-padding-y: 4px,
    button-sm-padding-x: 8px,
    button-sm-font-size: .875rem,

    // button large
    button-lg-padding-y: 12px,
    button-lg-padding-x: 16px,
    button-lg-font-size: 1.25rem,
);

.btn {
    @each $key, $value in $root-variables {
        --#{$key}: #{$value};
    }

    @apply inline-flex items-center justify-center border border-transparent font-semi;
    padding: var(--button-padding-y) var(--button-padding-x);
    font-size: var(--button-font-size);
    border-radius: var(--button-rounded);
    outline: 0;
    user-select: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &-gradient {
        border: unset;
    }

    @each $sizes in sm, lg {
        $button-y-size: var(--button-#{$sizes}-padding-y);
        $button-x-size: var(--button-#{$sizes}-padding-x);
        $button-font-size: var(--button-#{$sizes}-font-size);

        &-#{$sizes} {
            padding: $button-y-size $button-x-size;
            font-size: $button-font-size;
        }
    }
    
    @each $colors in primary, secondary {
        $button-color: var(--#{$colors}-color);
        $button-color-invert: var(--#{$colors}-color-invert);

        &-#{$colors} {
            background-color: $button-color !important;
            color: $button-color-invert;

            svg {
                fill: var(--#{$colors}-color-invert);
            }

            &:hover {
                background-color: var(--#{$colors}-color-hover) !important;
            }
        }

        &-outline-#{$colors} {
            border-color: $button-color;
            color: $button-color;

            svg {
                fill: $button-color;
            }

            &:hover {
                color: var(--#{$colors}-color-invert);
                background-color: $button-color !important;

                svg {
                    fill: var(--#{$colors}-color-invert);
                }
            }
        }
    }
}